*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Merriweather Sans, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])), main:target ~ main:last-of-type {
  display: none;
}

main:target {
  display: block;
}

.prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.prose :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.prose :where(a):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.prose :where(strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.prose :where(a strong):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(blockquote strong):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(thead th strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-inline-start: 1.625em;
  list-style-type: decimal;
}

.prose :where(ol[type="A"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="A" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="I"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="I" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="1"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: decimal;
}

.prose :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-inline-start: 1.625em;
  list-style-type: disc;
}

.prose :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.prose :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.prose :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.25em;
  font-weight: 600;
}

.prose :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-inline-start-width: .25rem;
  border-inline-start-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-inline-start: 1em;
  font-style: italic;
  font-weight: 500;
}

.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):before {
  content: open-quote;
}

.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: close-quote;
}

.prose :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.prose :where(h1 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 900;
}

.prose :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.prose :where(h2 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 800;
}

.prose :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.prose :where(h3 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.prose :where(h4 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
  display: block;
}

.prose :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-kbd);
  box-shadow: 0 0 0 1px rgb(var(--tw-prose-kbd-shadows) / 10%), 0 3px 0 rgb(var(--tw-prose-kbd-shadows) / 10%);
  padding-top: .1875em;
  padding-inline-end: .375em;
  padding-bottom: .1875em;
  border-radius: .3125rem;
  padding-inline-start: .375em;
  font-family: inherit;
  font-size: .875em;
  font-weight: 500;
}

.prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: "`";
}

.prose :where(a code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h1 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: .875em;
}

.prose :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: .9em;
}

.prose :where(h4 code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(blockquote code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(thead th code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  padding-top: .857143em;
  padding-inline-end: 1.14286em;
  padding-bottom: .857143em;
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding-inline-start: 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: none;
}

.prose :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  table-layout: auto;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.prose :where(thead):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.prose :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-inline-end: .571429em;
  padding-bottom: .571429em;
  padding-inline-start: .571429em;
  font-weight: 600;
}

.prose :where(tbody tr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.prose :where(tbody tr:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 0;
}

.prose :where(tbody td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: baseline;
}

.prose :where(tfoot):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}

.prose :where(tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: top;
}

.prose :where(th, td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  text-align: start;
}

.prose :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-kbd: #111827;
  --tw-prose-kbd-shadows: 17 24 39;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-kbd: #fff;
  --tw-prose-invert-kbd-shadows: 255 255 255;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.prose :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.prose :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: .375em;
}

.prose :where(.prose > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(.prose > ul > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ul > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(.prose > ol > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ol > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  padding-inline-start: 1.625em;
}

.prose :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: 0;
}

.prose :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-end: 0;
}

.prose :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-top: .571429em;
  padding-inline-end: .571429em;
  padding-bottom: .571429em;
  padding-inline-start: .571429em;
}

.prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: 0;
}

.prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-end: 0;
}

.prose :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(.prose > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(.prose > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 0;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.right-0 {
  right: 0;
}

.top-5 {
  top: 1.25rem;
}

.float-right {
  float: right;
}

.m-0 {
  margin: 0;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.mr-1 {
  margin-right: .25rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-16 {
  height: 4rem;
}

.h-2\/3screen {
  height: 66vh;
}

.h-full {
  height: 100%;
}

.max-h-5\/6 {
  max-height: 83%;
}

.min-h-500 {
  min-height: 500px;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-full {
  width: 100%;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-full {
  max-width: 100%;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-2 {
  gap: .5rem;
}

.overflow-hidden {
  overflow: hidden;
}

.border {
  border-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity, 1));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-yellow-custom1 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 188 114 / var(--tw-bg-opacity, 1));
}

.bg-opacity-40 {
  --tw-bg-opacity: .4;
}

.object-cover {
  object-fit: cover;
}

.object-right-top {
  object-position: right top;
}

.p-0 {
  padding: 0;
}

.p-2 {
  padding: .5rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pr-2 {
  padding-right: .5rem;
}

.align-middle {
  vertical-align: middle;
}

.font-serif {
  font-family: Playfair Display, ui-serif, Georgia, Cambria, Times New Roman, Times, serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.not-italic {
  font-style: normal;
}

.leading-none {
  line-height: 1;
}

.leading-snug {
  line-height: 1.375;
}

.text-blue-800 {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity, 1));
}

.text-red-950 {
  --tw-text-opacity: 1;
  color: rgb(69 10 10 / var(--tw-text-opacity, 1));
}

.outline {
  outline-style: solid;
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

@media (width >= 640px) {
  .sm\:inline-block {
    display: inline-block;
  }

  .sm\:w-1\/4 {
    width: 25%;
  }

  .sm\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .sm\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .sm\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (width >= 768px) {
  .md\:h-3\/4screen {
    height: 75vh;
  }

  .md\:w-1\/6 {
    width: 16.6667%;
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .md\:border-l-2 {
    border-left-width: 2px;
  }

  .md\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .md\:pl-3 {
    padding-left: .75rem;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (width >= 1024px) {
  .lg\:left-20 {
    left: 5rem;
  }

  .lg\:right-1\/2 {
    right: 50%;
  }

  .lg\:top-1\/4 {
    top: 25%;
  }
}

@media (width >= 1280px) {
  .xl\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
}

.\[\&\>\*\]\:my-3 > * {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.\[\&_\*\:\:part\(form-control-label\)\]\:text-base *::part(form-control-label) {
  font-size: 1rem;
  line-height: 1.5rem;
}

.\[\&_\[data-user-invalid\]\:\:part\(base\)\]\:border-yellow-custom1 [data-user-invalid]::part(base) {
  --tw-border-opacity: 1;
  border-color: rgb(248 188 114 / var(--tw-border-opacity, 1));
}

.\[\&_a\]\:my-2 a {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.\[\&_a\]\:block a {
  display: block;
}

.\[\&_p\]\:my-4 p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.\[\&_p\]\:mt-0 p {
  margin-top: 0;
}

.\[\&_p\]\:font-light p {
  font-weight: 300;
}

.\[\&_p_b\]\:block p b {
  display: block;
}

.\[\&_sl-checkbox\[data-user-invalid\]\:\:part\(control\)\]\:border-yellow-custom1 sl-checkbox[data-user-invalid]::part(control) {
  --tw-border-opacity: 1;
  border-color: rgb(248 188 114 / var(--tw-border-opacity, 1));
}

.\[\&_sl-checkbox\]\:my-3 sl-checkbox {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.\[\&_sl-input\:\:part\(input\)\]\:font-light sl-input::part(input) {
  font-weight: 300;
}

.\[\&_sl-input\]\:my-3 sl-input {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.\[\&_sl-radio-button\:\:part\(label\)\]\:font-light sl-radio-button::part(label) {
  font-weight: 300;
}

.\[\&_sl-radio-group\[data-user-invalid\]\:\:part\(form-control\)\]\:border-0 sl-radio-group[data-user-invalid]::part(form-control) {
  border-width: 0;
}

.\[\&_sl-radio-group\[data-user-invalid\]\:\:part\(form-control\)\]\:border-l-2 sl-radio-group[data-user-invalid]::part(form-control) {
  border-left-width: 2px;
}

.\[\&_sl-radio-group\[data-user-invalid\]\:\:part\(form-control\)\]\:border-solid sl-radio-group[data-user-invalid]::part(form-control) {
  border-style: solid;
}

.\[\&_sl-radio-group\[data-user-invalid\]\:\:part\(form-control\)\]\:border-yellow-custom1 sl-radio-group[data-user-invalid]::part(form-control) {
  --tw-border-opacity: 1;
  border-color: rgb(248 188 114 / var(--tw-border-opacity, 1));
}

.\[\&_sl-radio-group\[data-user-invalid\]\:\:part\(form-control\)\]\:pl-2 sl-radio-group[data-user-invalid]::part(form-control) {
  padding-left: .5rem;
}

.\[\&_sl-radio-group\]\:my-3 sl-radio-group {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.\[\&_sl-radio\:\:part\(label\)\]\:font-light sl-radio::part(label), .\[\&_sl-textarea\:\:part\(textarea\)\]\:font-light sl-textarea::part(textarea) {
  font-weight: 300;
}

.\[\&_sl-textarea\]\:my-3 sl-textarea {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.\[\&_ul\]\:font-light ul {
  font-weight: 300;
}

:root, :host, .sl-theme-light {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light;
  --sl-color-gray-50: #f9f9f9;
  --sl-color-gray-100: #f4f4f5;
  --sl-color-gray-200: #e4e4e7;
  --sl-color-gray-300: #d4d4d8;
  --sl-color-gray-400: #a1a1aa;
  --sl-color-gray-500: #71717a;
  --sl-color-gray-600: #52525b;
  --sl-color-gray-700: #3f3f46;
  --sl-color-gray-800: #27272a;
  --sl-color-gray-900: #18181b;
  --sl-color-gray-950: #131316;
  --sl-color-red-50: #fef2f2;
  --sl-color-red-100: #fee2e2;
  --sl-color-red-200: #fecaca;
  --sl-color-red-300: #fca5a5;
  --sl-color-red-400: #f87171;
  --sl-color-red-500: #ef4444;
  --sl-color-red-600: #dc2626;
  --sl-color-red-700: #b91c1c;
  --sl-color-red-800: #991b1b;
  --sl-color-red-900: #7f1d1d;
  --sl-color-red-950: #501414;
  --sl-color-orange-50: #fff7ed;
  --sl-color-orange-100: #ffedd5;
  --sl-color-orange-200: #fed7aa;
  --sl-color-orange-300: #fdba74;
  --sl-color-orange-400: #fb923c;
  --sl-color-orange-500: #f97316;
  --sl-color-orange-600: #ea580c;
  --sl-color-orange-700: #c2410c;
  --sl-color-orange-800: #9a3412;
  --sl-color-orange-900: #7c2d12;
  --sl-color-orange-950: #52200f;
  --sl-color-amber-50: #fffbeb;
  --sl-color-amber-100: #fef3c7;
  --sl-color-amber-200: #fde68a;
  --sl-color-amber-300: #fcd34d;
  --sl-color-amber-400: #fbbf24;
  --sl-color-amber-500: #f59e0b;
  --sl-color-amber-600: #d97706;
  --sl-color-amber-700: #b45309;
  --sl-color-amber-800: #92400e;
  --sl-color-amber-900: #78350f;
  --sl-color-amber-950: #4a230b;
  --sl-color-yellow-50: #fefce8;
  --sl-color-yellow-100: #fef9c3;
  --sl-color-yellow-200: #fef08a;
  --sl-color-yellow-300: #fde047;
  --sl-color-yellow-400: #facc15;
  --sl-color-yellow-500: #eab308;
  --sl-color-yellow-600: #ca8a04;
  --sl-color-yellow-700: #a16207;
  --sl-color-yellow-800: #854d0e;
  --sl-color-yellow-900: #713f12;
  --sl-color-yellow-950: #3c260b;
  --sl-color-lime-50: #f7fee7;
  --sl-color-lime-100: #ecfccb;
  --sl-color-lime-200: #d9f99d;
  --sl-color-lime-300: #bef264;
  --sl-color-lime-400: #a3e635;
  --sl-color-lime-500: #84cc16;
  --sl-color-lime-600: #65a30d;
  --sl-color-lime-700: #4d7c0f;
  --sl-color-lime-800: #3f6212;
  --sl-color-lime-900: #365314;
  --sl-color-lime-950: #26390e;
  --sl-color-green-50: #f0fdf4;
  --sl-color-green-100: #dcfce7;
  --sl-color-green-200: #bbf7d0;
  --sl-color-green-300: #86efac;
  --sl-color-green-400: #4ade80;
  --sl-color-green-500: #22c55e;
  --sl-color-green-600: #16a34a;
  --sl-color-green-700: #15803d;
  --sl-color-green-800: #166534;
  --sl-color-green-900: #14532d;
  --sl-color-green-950: #0c311b;
  --sl-color-emerald-50: #ecfdf5;
  --sl-color-emerald-100: #d1fae5;
  --sl-color-emerald-200: #a7f3d0;
  --sl-color-emerald-300: #6ee7b7;
  --sl-color-emerald-400: #34d399;
  --sl-color-emerald-500: #10b981;
  --sl-color-emerald-600: #059669;
  --sl-color-emerald-700: #047857;
  --sl-color-emerald-800: #065f46;
  --sl-color-emerald-900: #064e3b;
  --sl-color-emerald-950: #032d22;
  --sl-color-teal-50: #f0fdfa;
  --sl-color-teal-100: #ccfbf1;
  --sl-color-teal-200: #99f6e4;
  --sl-color-teal-300: #5eead4;
  --sl-color-teal-400: #2dd4bf;
  --sl-color-teal-500: #14b8a6;
  --sl-color-teal-600: #0d9488;
  --sl-color-teal-700: #0f766e;
  --sl-color-teal-800: #115e59;
  --sl-color-teal-900: #134e4a;
  --sl-color-teal-950: #0c2e2c;
  --sl-color-cyan-50: #ecfeff;
  --sl-color-cyan-100: #cffafe;
  --sl-color-cyan-200: #a5f3fc;
  --sl-color-cyan-300: #67e8f9;
  --sl-color-cyan-400: #22d3ee;
  --sl-color-cyan-500: #06b6d4;
  --sl-color-cyan-600: #0891b2;
  --sl-color-cyan-700: #0e7490;
  --sl-color-cyan-800: #155e75;
  --sl-color-cyan-900: #164e63;
  --sl-color-cyan-950: #103442;
  --sl-color-sky-50: #f0f9ff;
  --sl-color-sky-100: #e0f2fe;
  --sl-color-sky-200: #bae6fd;
  --sl-color-sky-300: #7dd3fc;
  --sl-color-sky-400: #38bdf8;
  --sl-color-sky-500: #0ea5e9;
  --sl-color-sky-600: #0284c7;
  --sl-color-sky-700: #0369a1;
  --sl-color-sky-800: #075985;
  --sl-color-sky-900: #0c4a6e;
  --sl-color-sky-950: #0b3249;
  --sl-color-blue-50: #eff6ff;
  --sl-color-blue-100: #dbeafe;
  --sl-color-blue-200: #bfdbfe;
  --sl-color-blue-300: #93c5fd;
  --sl-color-blue-400: #60a5fa;
  --sl-color-blue-500: #3b82f6;
  --sl-color-blue-600: #2563eb;
  --sl-color-blue-700: #1d4ed8;
  --sl-color-blue-800: #1e40af;
  --sl-color-blue-900: #1e3a8a;
  --sl-color-blue-950: #152149;
  --sl-color-indigo-50: #eef2ff;
  --sl-color-indigo-100: #e0e7ff;
  --sl-color-indigo-200: #c7d2fe;
  --sl-color-indigo-300: #a5b4fc;
  --sl-color-indigo-400: #818cf8;
  --sl-color-indigo-500: #6366f1;
  --sl-color-indigo-600: #4f46e5;
  --sl-color-indigo-700: #4338ca;
  --sl-color-indigo-800: #3730a3;
  --sl-color-indigo-900: #312e81;
  --sl-color-indigo-950: #242154;
  --sl-color-violet-50: #f5f3ff;
  --sl-color-violet-100: #ede9fe;
  --sl-color-violet-200: #ddd6fe;
  --sl-color-violet-300: #c4b5fd;
  --sl-color-violet-400: #a78bfa;
  --sl-color-violet-500: #8b5cf6;
  --sl-color-violet-600: #7c3aed;
  --sl-color-violet-700: #6d28d9;
  --sl-color-violet-800: #5b21b6;
  --sl-color-violet-900: #4c1d95;
  --sl-color-violet-950: #311558;
  --sl-color-purple-50: #faf5ff;
  --sl-color-purple-100: #f3e8ff;
  --sl-color-purple-200: #e9d5ff;
  --sl-color-purple-300: #d8b4fe;
  --sl-color-purple-400: #c084fc;
  --sl-color-purple-500: #a855f7;
  --sl-color-purple-600: #9333ea;
  --sl-color-purple-700: #7e22ce;
  --sl-color-purple-800: #6b21a8;
  --sl-color-purple-900: #581c87;
  --sl-color-purple-950: #2f1143;
  --sl-color-fuchsia-50: #fdf4ff;
  --sl-color-fuchsia-100: #fae8ff;
  --sl-color-fuchsia-200: #f5d0fe;
  --sl-color-fuchsia-300: #f0abfc;
  --sl-color-fuchsia-400: #e879f9;
  --sl-color-fuchsia-500: #d946ef;
  --sl-color-fuchsia-600: #c026d3;
  --sl-color-fuchsia-700: #a21caf;
  --sl-color-fuchsia-800: #86198f;
  --sl-color-fuchsia-900: #701a75;
  --sl-color-fuchsia-950: #38103a;
  --sl-color-pink-50: #fdf2f8;
  --sl-color-pink-100: #fce7f3;
  --sl-color-pink-200: #fbcfe8;
  --sl-color-pink-300: #f9a8d4;
  --sl-color-pink-400: #f472b6;
  --sl-color-pink-500: #ec4899;
  --sl-color-pink-600: #db2777;
  --sl-color-pink-700: #be185d;
  --sl-color-pink-800: #9d174d;
  --sl-color-pink-900: #831843;
  --sl-color-pink-950: #430e23;
  --sl-color-rose-50: #fff1f2;
  --sl-color-rose-100: #ffe4e6;
  --sl-color-rose-200: #fecdd3;
  --sl-color-rose-300: #fda4af;
  --sl-color-rose-400: #fb7185;
  --sl-color-rose-500: #f43f5e;
  --sl-color-rose-600: #e11d48;
  --sl-color-rose-700: #be123c;
  --sl-color-rose-800: #9f1239;
  --sl-color-rose-900: #881337;
  --sl-color-rose-950: #4a0d20;
  --sl-color-primary-50: var(--sl-color-sky-50);
  --sl-color-primary-100: var(--sl-color-sky-100);
  --sl-color-primary-200: var(--sl-color-sky-200);
  --sl-color-primary-300: var(--sl-color-sky-300);
  --sl-color-primary-400: var(--sl-color-sky-400);
  --sl-color-primary-500: var(--sl-color-sky-500);
  --sl-color-primary-600: var(--sl-color-sky-600);
  --sl-color-primary-700: var(--sl-color-sky-700);
  --sl-color-primary-800: var(--sl-color-sky-800);
  --sl-color-primary-900: var(--sl-color-sky-900);
  --sl-color-primary-950: var(--sl-color-sky-950);
  --sl-color-success-50: var(--sl-color-green-50);
  --sl-color-success-100: var(--sl-color-green-100);
  --sl-color-success-200: var(--sl-color-green-200);
  --sl-color-success-300: var(--sl-color-green-300);
  --sl-color-success-400: var(--sl-color-green-400);
  --sl-color-success-500: var(--sl-color-green-500);
  --sl-color-success-600: var(--sl-color-green-600);
  --sl-color-success-700: var(--sl-color-green-700);
  --sl-color-success-800: var(--sl-color-green-800);
  --sl-color-success-900: var(--sl-color-green-900);
  --sl-color-success-950: var(--sl-color-green-950);
  --sl-color-warning-50: var(--sl-color-amber-50);
  --sl-color-warning-100: var(--sl-color-amber-100);
  --sl-color-warning-200: var(--sl-color-amber-200);
  --sl-color-warning-300: var(--sl-color-amber-300);
  --sl-color-warning-400: var(--sl-color-amber-400);
  --sl-color-warning-500: var(--sl-color-amber-500);
  --sl-color-warning-600: var(--sl-color-amber-600);
  --sl-color-warning-700: var(--sl-color-amber-700);
  --sl-color-warning-800: var(--sl-color-amber-800);
  --sl-color-warning-900: var(--sl-color-amber-900);
  --sl-color-warning-950: var(--sl-color-amber-950);
  --sl-color-danger-50: var(--sl-color-red-50);
  --sl-color-danger-100: var(--sl-color-red-100);
  --sl-color-danger-200: var(--sl-color-red-200);
  --sl-color-danger-300: var(--sl-color-red-300);
  --sl-color-danger-400: var(--sl-color-red-400);
  --sl-color-danger-500: var(--sl-color-red-500);
  --sl-color-danger-600: var(--sl-color-red-600);
  --sl-color-danger-700: var(--sl-color-red-700);
  --sl-color-danger-800: var(--sl-color-red-800);
  --sl-color-danger-900: var(--sl-color-red-900);
  --sl-color-danger-950: var(--sl-color-red-950);
  --sl-color-neutral-50: var(--sl-color-gray-50);
  --sl-color-neutral-100: var(--sl-color-gray-100);
  --sl-color-neutral-200: var(--sl-color-gray-200);
  --sl-color-neutral-300: var(--sl-color-gray-300);
  --sl-color-neutral-400: var(--sl-color-gray-400);
  --sl-color-neutral-500: var(--sl-color-gray-500);
  --sl-color-neutral-600: var(--sl-color-gray-600);
  --sl-color-neutral-700: var(--sl-color-gray-700);
  --sl-color-neutral-800: var(--sl-color-gray-800);
  --sl-color-neutral-900: var(--sl-color-gray-900);
  --sl-color-neutral-950: var(--sl-color-gray-950);
  --sl-color-neutral-0: #fff;
  --sl-color-neutral-1000: #000;
  --sl-border-radius-small: .1875rem;
  --sl-border-radius-medium: .25rem;
  --sl-border-radius-large: .5rem;
  --sl-border-radius-x-large: 1rem;
  --sl-border-radius-circle: 50%;
  --sl-border-radius-pill: 9999px;
  --sl-shadow-x-small: 0 1px 2px #71717a0f;
  --sl-shadow-small: 0 1px 2px #71717a1f;
  --sl-shadow-medium: 0 2px 4px #71717a1f;
  --sl-shadow-large: 0 2px 8px #71717a1f;
  --sl-shadow-x-large: 0 4px 16px #71717a1f;
  --sl-spacing-3x-small: .125rem;
  --sl-spacing-2x-small: .25rem;
  --sl-spacing-x-small: .5rem;
  --sl-spacing-small: .75rem;
  --sl-spacing-medium: 1rem;
  --sl-spacing-large: 1.25rem;
  --sl-spacing-x-large: 1.75rem;
  --sl-spacing-2x-large: 2.25rem;
  --sl-spacing-3x-large: 3rem;
  --sl-spacing-4x-large: 4.5rem;
  --sl-transition-x-slow: 1s;
  --sl-transition-slow: .5s;
  --sl-transition-medium: .25s;
  --sl-transition-fast: .15s;
  --sl-transition-x-fast: 50ms;
  --sl-font-mono: SFMono-Regular, Consolas, "Liberation Mono", Menlo, monospace;
  --sl-font-sans: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --sl-font-serif: Georgia, "Times New Roman", serif;
  --sl-font-size-2x-small: .625rem;
  --sl-font-size-x-small: .75rem;
  --sl-font-size-small: .875rem;
  --sl-font-size-medium: 1rem;
  --sl-font-size-large: 1.25rem;
  --sl-font-size-x-large: 1.5rem;
  --sl-font-size-2x-large: 2.25rem;
  --sl-font-size-3x-large: 3rem;
  --sl-font-size-4x-large: 4.5rem;
  --sl-font-weight-light: 300;
  --sl-font-weight-normal: 400;
  --sl-font-weight-semibold: 500;
  --sl-font-weight-bold: 700;
  --sl-letter-spacing-denser: -.03em;
  --sl-letter-spacing-dense: -.015em;
  --sl-letter-spacing-normal: normal;
  --sl-letter-spacing-loose: .075em;
  --sl-letter-spacing-looser: .15em;
  --sl-line-height-denser: 1;
  --sl-line-height-dense: 1.4;
  --sl-line-height-normal: 1.8;
  --sl-line-height-loose: 2.2;
  --sl-line-height-looser: 2.6;
  --sl-focus-ring-color: var(--sl-color-primary-600);
  --sl-focus-ring-style: solid;
  --sl-focus-ring-width: 3px;
  --sl-focus-ring: var(--sl-focus-ring-style) var(--sl-focus-ring-width) var(--sl-focus-ring-color);
  --sl-focus-ring-offset: 1px;
  --sl-button-font-size-small: var(--sl-font-size-x-small);
  --sl-button-font-size-medium: var(--sl-font-size-small);
  --sl-button-font-size-large: var(--sl-font-size-medium);
  --sl-input-height-small: 1.875rem;
  --sl-input-height-medium: 2.5rem;
  --sl-input-height-large: 3.125rem;
  --sl-input-background-color: var(--sl-color-neutral-0);
  --sl-input-background-color-hover: var(--sl-input-background-color);
  --sl-input-background-color-focus: var(--sl-input-background-color);
  --sl-input-background-color-disabled: var(--sl-color-neutral-100);
  --sl-input-border-color: var(--sl-color-neutral-300);
  --sl-input-border-color-hover: var(--sl-color-neutral-400);
  --sl-input-border-color-focus: var(--sl-color-primary-500);
  --sl-input-border-color-disabled: var(--sl-color-neutral-300);
  --sl-input-border-width: 1px;
  --sl-input-required-content: "*";
  --sl-input-required-content-offset: -2px;
  --sl-input-required-content-color: var(--sl-input-label-color);
  --sl-input-border-radius-small: var(--sl-border-radius-medium);
  --sl-input-border-radius-medium: var(--sl-border-radius-medium);
  --sl-input-border-radius-large: var(--sl-border-radius-medium);
  --sl-input-font-family: var(--sl-font-sans);
  --sl-input-font-weight: var(--sl-font-weight-normal);
  --sl-input-font-size-small: var(--sl-font-size-small);
  --sl-input-font-size-medium: var(--sl-font-size-medium);
  --sl-input-font-size-large: var(--sl-font-size-large);
  --sl-input-letter-spacing: var(--sl-letter-spacing-normal);
  --sl-input-color: var(--sl-color-neutral-700);
  --sl-input-color-hover: var(--sl-color-neutral-700);
  --sl-input-color-focus: var(--sl-color-neutral-700);
  --sl-input-color-disabled: var(--sl-color-neutral-900);
  --sl-input-icon-color: var(--sl-color-neutral-500);
  --sl-input-icon-color-hover: var(--sl-color-neutral-600);
  --sl-input-icon-color-focus: var(--sl-color-neutral-600);
  --sl-input-placeholder-color: var(--sl-color-neutral-500);
  --sl-input-placeholder-color-disabled: var(--sl-color-neutral-600);
  --sl-input-spacing-small: var(--sl-spacing-small);
  --sl-input-spacing-medium: var(--sl-spacing-medium);
  --sl-input-spacing-large: var(--sl-spacing-large);
  --sl-input-focus-ring-color: #0ea5e966;
  --sl-input-focus-ring-offset: 0;
  --sl-input-filled-background-color: var(--sl-color-neutral-100);
  --sl-input-filled-background-color-hover: var(--sl-color-neutral-100);
  --sl-input-filled-background-color-focus: var(--sl-color-neutral-100);
  --sl-input-filled-background-color-disabled: var(--sl-color-neutral-100);
  --sl-input-filled-color: var(--sl-color-neutral-800);
  --sl-input-filled-color-hover: var(--sl-color-neutral-800);
  --sl-input-filled-color-focus: var(--sl-color-neutral-700);
  --sl-input-filled-color-disabled: var(--sl-color-neutral-800);
  --sl-input-label-font-size-small: var(--sl-font-size-small);
  --sl-input-label-font-size-medium: var(--sl-font-size-medium);
  --sl-input-label-font-size-large: var(--sl-font-size-large);
  --sl-input-label-color: inherit;
  --sl-input-help-text-font-size-small: var(--sl-font-size-x-small);
  --sl-input-help-text-font-size-medium: var(--sl-font-size-small);
  --sl-input-help-text-font-size-large: var(--sl-font-size-medium);
  --sl-input-help-text-color: var(--sl-color-neutral-500);
  --sl-toggle-size-small: .875rem;
  --sl-toggle-size-medium: 1.125rem;
  --sl-toggle-size-large: 1.375rem;
  --sl-overlay-background-color: #71717a54;
  --sl-panel-background-color: var(--sl-color-neutral-0);
  --sl-panel-border-color: var(--sl-color-neutral-200);
  --sl-panel-border-width: 1px;
  --sl-tooltip-border-radius: var(--sl-border-radius-medium);
  --sl-tooltip-background-color: var(--sl-color-neutral-800);
  --sl-tooltip-color: var(--sl-color-neutral-0);
  --sl-tooltip-font-family: var(--sl-font-sans);
  --sl-tooltip-font-weight: var(--sl-font-weight-normal);
  --sl-tooltip-font-size: var(--sl-font-size-small);
  --sl-tooltip-line-height: var(--sl-line-height-dense);
  --sl-tooltip-padding: var(--sl-spacing-2x-small) var(--sl-spacing-x-small);
  --sl-tooltip-arrow-size: 6px;
  --sl-z-index-drawer: 700;
  --sl-z-index-dialog: 800;
  --sl-z-index-dropdown: 900;
  --sl-z-index-toast: 950;
  --sl-z-index-tooltip: 1000;
}

@supports (scrollbar-gutter: stable) {
  .sl-scroll-lock {
    scrollbar-gutter: var(--sl-scroll-lock-gutter) !important;
  }

  .sl-scroll-lock body {
    overflow: hidden !important;
  }
}

@supports not (scrollbar-gutter: stable) {
  .sl-scroll-lock body {
    padding-right: var(--sl-scroll-lock-size) !important;
    overflow: hidden !important;
  }
}

.sl-toast-stack {
  z-index: var(--sl-z-index-toast);
  top: 0;
  width: 28rem;
  max-width: 100%;
  max-height: 100%;
  position: fixed;
  inset-inline-end: 0;
  overflow: auto;
}

.sl-toast-stack sl-alert {
  margin: var(--sl-spacing-medium);
}

.sl-toast-stack sl-alert::part(base) {
  box-shadow: var(--sl-shadow-large);
}

:root, :host, .sl-theme-light {
  --sl-input-font-family: inherit;
}

#video_container sl-button::part(base) {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
  background-color: rgb(202 138 4 / var(--tw-bg-opacity, 1));
  --tw-bg-opacity: .1;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  font-weight: 600;
}

#video_container sl-button::part(base):hover {
  --tw-bg-opacity: .4;
}

.card-header::part(base) {
  height: 100%;
}

.card-header {
  --padding: .75em;
  line-height: 1.5;
}

.card-header [slot="header"] {
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  display: flex;
}

.card-header sl-icon {
  font-size: var(--sl-font-size-large);
  flex-shrink: 0;
}

.secret {
  -webkit-user-select: none;
  user-select: none;
  color: #fff;
  z-index: -99999;
  font-size: 1px;
  position: absolute;
}
/*# sourceMappingURL=index.44da92e5.css.map */
