/*to apply tailwind styles to shoelace components, target ::part(base) or the specific part of the component, and use @apply  */
/* for ease of use, keep as much within HTML as possible, e.g. [&>[data-user-invalid]::part(base)]:border-red-600 */
/*  */
/**********************************************************************/

/* fix shoelace coming up with its own fonts */
:root, :host, .sl-theme-light  {  
  --sl-input-font-family: inherit;
}


/* video */
#video_container sl-button::part(base) {
  @apply text-black font-semibold shadow-md bg-yellow-600 bg-opacity-10 border-black;
}

#video_container sl-button::part(base):hover {
  @apply bg-opacity-40; 
}


/****** cards ***********/

.card-header::part(base) {
    height: 100%;
}

.card-header {
    line-height: 1.5;
    --padding: 0.75em;
}
  
.card-header [slot="header"] {
    display: flex; 
    align-items: center; 
    justify-content: space-between;
    font-weight: bold;
    /*min-height:5.5ex;*/

}

.card-header sl-icon {    
    font-size: var(--sl-font-size-large);
    flex-shrink: 0;
}


/* **************  */

.secret {
  font-size: 1px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: white;
  position: absolute;
  z-index: -99999;
  } 