@tailwind base;

@layer base {   /*where you can re-add styles to base elements that tailwind got rid of */

  /* dont add h1, h2 etc, use 'prose' instead */

  main:target ~ main:last-of-type {display: none; } 
  /* hide the default & last <main> when it follows a targeted <main> */

  main:target { display: block; }
  /* show a targeted tab */

}

@tailwind components;
@tailwind utilities;
